<template lang="pug">
  app-dialog(title="Updating feedback status" :value="showing" width="320" @click:outside="cancel" @input="cancel")
    template(#dialog-action)
      slot(name="activator" :toggle="toggle")
    template(#dialog-body)
      .change-status__body
        app-select(
          :options="FEEDBACK_STATUS_LIST"
          v-model="status"
          placeholder="Feedback status"
          slug-key='value'
          slug-value='name'
          show-close-btn
        ).no-margin
    template(#dialog-footer)
      v-spacer
      app-button(color="#f4f7f9" hide-shadow @click.native="cancel").config-cancel.unselectable.mr-2 Cancel
      app-button(color="primary" :disabled="isDisabled" hide-shadow @click.native="submit") Change
</template>

<script>
import { FEEDBACK_STATUS_LIST } from '../../core/feedback-const'
import showingMixin from '@/mixins/showing.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from '@/util/form-errors'
import appButton from '@/components/global/actions/BaseButton.vue'

export default {
  name: 'ChangeStatus',

  mixins: [errorsMixin, showingMixin],

  inject: ['feedbackService'],

  props: {
    ID: Number,
    value: String,
    items: Array,
  },

  computed: {
    isDisabled() {
      return !this.status || (this.status === this.value)
    }
  },

  data: () => ({
    status: '',
    formErrors: new FormErrors(),
    loading: false,
    FEEDBACK_STATUS_LIST
  }),

  methods: {
    async submit() {
      if (this.ID && !this.items) await this.updateStatus()
      else await this.updateBulkStatus()
    },

    async updateStatus() {
      try {
        this.loading = true
        await this.feedbackService.updateStatus(this.ID, this.status)
        this.$notify({type: 'success', text: 'Successful updated'})
        this.$emit('change', this.ID)
        this.toggle()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async updateBulkStatus() {
      try {
        this.loading = true
        let getIDList = this.items.map(item => item.id)
        await this.feedbackService.updateBulkStatus(getIDList, this.status)
        this.$emit('needToUpdate')
        this.$notify({type: 'success', text: 'Successful updated'})
        this.toggle()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    cancel() {
      this.updateShowing(false)
    },

    prefill() {
      this.status = this.value
    },

    reset() {
      this.status = ''
    }
  },

  watch: {
    showing(isShowing) {
      if (!isShowing) this.reset()
      else this.prefill()
    }
  },

  components: {
    appButton,
    appSelect: () => import('@/components/global/actions/BaseSelect.vue'),
    appDialog: () => import('@/components/global/actions/BaseDialog.vue')
  }
}
</script>

<style lang="scss">
.config-cancel {
  color: $label-color;
}

.change-status__body {
  padding: 15px;
}
</style>
